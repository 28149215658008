import { AccountCircle, CalendarMonth, CalendarViewDay, Chat, ChatBubble, ContactPage, CorporateFare, DocumentScanner, DocumentScannerOutlined, DynamicFeed, Email, EmailOutlined, EmailRounded, Facebook, FolderCopy, Inventory, LockClock, People, PermContactCalendar, ProductionQuantityLimits, RestoreFromTrash, SmartToy } from "@mui/icons-material";

import PeopleIcon from '@mui/icons-material/People';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import DescriptionIcon from '@mui/icons-material/Description';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Send from "@mui/icons-material/Send";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const moduleSection = [
  {
    href: "/inicio/modulos",
    title: "Modulos",
    icon: ViewModuleIcon,
    permission: ['Agregar modulos']
  },
];

const adminSection = [

  {
    href: "/administracion/usuarios",
    title: "Usuarios",
    icon: PeopleIcon,
    //badge: "3",
    permission: ['Modificar Usuarios', 'Agregar Usuarios', 'Eliminar Usuarios']
  },
  {
    href: "/administracion/permisos",
    title: "Roles y permisos",
    icon: AdminPanelSettingsIcon,
    //badge: "3",
    permission: ['Modificar Perfil', 'Agregar Perfil', 'Eliminar Perfil']
  },
  {
    href: "/administracion/modulos",
    title: "Modulos",
    icon: ViewModuleIcon,
    permission: ['Agregar modulos']
  },
  {
    href: "/administracion/horarios",
    title: "Horarios",
    icon: LockClock,
    permission: ['Mostrar panel de horas']
  },
];

const socialNetworks = [

  {
    href: "/social/cuentas",
    title: "Cuentas",
    icon: FacebookIcon,
    //badge: "3",
    permission: ['Sincronizar cuenta social', 'Eliminar cuenta social']
  },
  {
    href: "/social/posts",
    title: "Facebook Posts",
    icon: DynamicFeed,
    //badge: "3",
    permission: ['Sincronizar cuenta social', 'Eliminar cuenta social']
  },
  {
    href: "/social/formularios",
    title: "Facebook Forms",
    icon: DynamicFeed,
    //badge: "3",
    permission: ['Sincronizar cuenta social', 'Eliminar cuenta social']
  },
  {
    href: "/social/whatsapp",
    title: "Whatsapp",
    icon: WhatsAppIcon,
    //badge: "3",
    permission: ['Modificar Whatsapp', 'Agregar Whatsapp', 'Eliminar Whatsapp']
  },
  {
    href: "/social/twilio",
    title: "Twilio",
    icon: PhoneMissedIcon,
    //badge: "3",
    permission: ['Agregar telefono twilio', 'Eliminar telefono twilio', 'Editar telefono twilio']
  },
];


const clientsSection = [

  {
    href: '/clientes/ver-libros',
    title: "Libros",
    icon: MenuBookIcon,
    permission: ['Crear libros', 'Eliminar libros', 'Modificar libros']
  },
  {
    href: "/clientes/matriz",
    title: "Matriz de clientes",
    icon: FormatListNumberedRtlIcon,
    permission: ['Eliminar libros', 'Modificar libros']
  },
  {
    href: "/clientes/atencion",
    title: "Atención de clientes",
    icon: AssignmentIndIcon,
    permission: ['Atender clientes']
  },
  {
    href: "/clientes/papelera",
    title: "Papelera",
    icon: RestoreFromTrash,
    permission: ['Restaurar datos', 'Vaciar papelera']
  },
];



const recordSection = [
  {
    href: "/bitacora/llamadas",
    title: "Historial de llamadas",
    icon: CallIcon,
    //permission:['Bitacora llamadas Masivas']
    permission: ['Bitacora Envio Masivo']
  },

  {
    href: "/bitacora/mensajes",
    title: "Historial de mensajes",
    icon: MessageIcon,
    permission: ['Bitacora Envio Masivo']
  },
  {
    href: "/bitacora/fb-mensajes",
    title: "Historial de mensajes FB",
    icon: MessageIcon,
    permission: ['Bitacora Envio Masivo']
  },
  {
    href: "/bitacora/correos",
    title: "Historial de correos",
    icon: EmailOutlined,
    permission: ['Bitacora Envio Masivo']
  },
];

const customSection = [

  {
    href: "/personalizacion/etiquetas",
    title: "Etiquetas para clientes",
    icon: LocalOfferIcon,
    permission: ['Modificar categorias', 'Agregar categorias', 'Eliminar categorias']
  },
  {
    href: "/personalizacion/estatus",
    title: "Estatus de clientes",
    icon: AccountTreeIcon,
    permission: ['Modificar estatus', 'Agregar estatus', 'Eliminar estatus']
  },
  {
    href: "/personalizacion/productos",
    title: "Productos",
    icon: ProductionQuantityLimits,
    permission: ['Editar productos', 'Agregar productos', 'Eliminar productos']
  },
  {
    href: "/personalizacion/inventario",
    title: "Inventario",
    icon: Inventory,
    permission: ['Editar productos', 'Agregar productos', 'Eliminar productos']
  },
];

const calendarSection = [

  {
    href: "/calendario/citas",
    title: "Calendario de citas",
    icon: CalendarMonth,
    permission: ['Modificar categorias']
  },
  {
    href: "/calendario/configuracion",
    title: "Configurar calendario",
    icon: CalendarViewDay,
    permission: ['Modificar categorias']
  }
];

const templatesSection = [
  {
    href: "/plantillas/plantillas",
    title: "Plantillas",
    icon: DescriptionIcon,
    permission: ['Modificar Plantilla', 'Agregar Plantilla', 'Eliminar Plantilla']
  },
  {
    href: "/plantillas/email",
    title: "Crear plantilla de correo",
    icon: Email,
    permission: ['Modificar Plantilla', 'Agregar Plantilla', 'Eliminar Plantilla']
  },
  {
    href: "/plantillas/cotizacion",
    title: "Crear plantilla de cotización",
    icon: DocumentScanner,
    permission: ['Modificar Plantilla', 'Agregar Plantilla', 'Eliminar Plantilla']
  },
  {
    href: "/plantillas/secuencias",
    title: "Sequencias de envio",
    icon: DescriptionIcon,
    permission: ['Modificar Plantilla', 'Agregar Plantilla', 'Eliminar Plantilla']
  },
  {
    href: "/plantillas/speach",
    title: "Speachs",
    icon: RecordVoiceOverIcon,
    permission: ['Modificar Speech', 'Agregar Speech', 'Eliminar Speech']
  },
];

const storageSection = [
  {
    href: "/almacenamiento/archivos",
    title: "Archivos",
    icon: FolderCopy,
    permission: ['Modificar archivos', 'Agregar archivos', 'Eliminar archivos']
  },

];

const generalAdminSection = [

  {
    href: "/admin/membresias",
    title: "Paquetes",
    icon: LocalOfferIcon,
    permission: ['Agregar planes', 'Modificar planes', 'Eliminar planes']
  },
  {
    href: "/admin/organizaciones",
    title: "Organizaciones",
    icon: AccountTreeIcon,
    permission: ['Agregar organizaciones', 'Modificar organizaciones', 'Eliminar organizaciones']
  },

];



const chatbotItems = [
  {
    href: "/chatbot/activacion",
    title: "ChatBot IA",
    icon: SmartToy,
    permission: ['Activar chatbot']
  },

  {
    href: "/chatbot/tradicional",
    title: "ChatBot Tradicional",
    icon: ChatBubble,
    permission: ['Activar chatbot']
  },

  {
    href: "/chatbot/plantillas",
    title: "Plantillas chatbot",
    icon: DescriptionIcon,
    permission: ['Activar chatbot']
  },
];


const estatesSection = [
  {
    href: "/inmobiliaria/propiedades",
    title: "Inmobiliaria",
    icon: CorporateFare,
    permission: ['Administrar propiedades']
  },

];
const multiagente = [
  {
    href: "/social/whatsapp",
    title: "Whatsapp",
    icon: WhatsAppIcon,
    //badge: "3",
    permission: ['Modificar Whatsapp', 'Agregar Whatsapp', 'Eliminar Whatsapp']
  },
  {
    href: "/multiagente/chat",
    title: "Chat",
    icon: Chat,
    permission: ['Atender clientes']
  },
  {
    href: "/multiagente/fb-chat",
    title: "FB Chat",
    icon: Facebook,
    permission: ['Atender clientes']
  },
  {
    href: "/multiagente/emails",
    title: "Bandeja de entrada",
    icon: EmailRounded,
    permission: ['Atender clientes']
  },
  {
    href: "/multiagente/contactos",
    title: "Contactos",
    icon: ContactPage,
    permission: ['Atender clientes']
  },

];

const googleContacts = [
  {
    href: "/contacts/accounts",
    title: "Cuentas",
    icon: AccountCircle,
    //badge: "3",
    permission: ['Sincronizar cuenta de google']
  },
  {
    href: "/contacts/list",
    title: "Contactos",
    icon: PermContactCalendar,
    permission: ['Agregar contactos']
  },

];

const massive = [

  {
    href: "/masivo/envio",
    title: "Envio masivo",
    icon: Send,
    permission: ['Enviar mensaje masivo']
  },
  {
    href: "/masivo/secuencia",
    title: "Envio masivo de secuencias",
    icon: Send,
    permission: ['Enviar mensaje masivo']
  },

  {
    href: "/masivo/correo",
    title: "Envio masivo de correos",
    icon: Email,
    permission: ['Enviar mensaje masivo']
  },
  {
    href: "/masivo/etiquetado",
    title: "Etiquetado masivo",
    icon: LocalOfferIcon,
    permission: ['Enviar mensaje masivo']
  },

];

const quotes = [

  {
    href: "/cotizaciones/lista",
    title: "Ver cotizaciones",
    icon: DocumentScannerOutlined,
    permission: ['Ver cotizaciones']
  },

  {
    href: "/facturas/lista",
    title: "Ver facturas",
    icon: DocumentScannerOutlined,
    permission: ['Ver cotizaciones']
  },
];

const navItems = [
  {
    title: "Adminsitración General",
    pages: generalAdminSection,
  },
  {
    title: "Redes Sociales",
    pages: socialNetworks,
  },
  {
    title: "Plantillas",
    pages: templatesSection,
  },
  {
    title: "Masivo",
    pages: massive,
  },
  {
    title: "Almacenamiento",
    pages: storageSection,
  },
  {
    title: "Facturas",
    pages: quotes,
  },
  {
    title: "Clientes potenciales",
    pages: clientsSection,
  },
  {
    title: "Google Contacts",
    pages: googleContacts,
  },
  {
    title: "Inmobiliaria",
    pages: estatesSection,
  },
  {
    title: "Chat",
    pages: multiagente,
  },
  {
    title: "ChatBot",
    pages: chatbotItems,
  },
  {
    title: "Personalización",
    pages: customSection,
  },
  {
    title: "Calendario",
    pages: calendarSection
  },
  {
    title: "Bitacora",
    pages: recordSection,
  },
  {
    title: "Administracion",
    pages: adminSection,
  },
];
export default navItems;
